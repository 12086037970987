body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiButtonBase-root {
  outline: none !important;
}

a {
  text-decoration: none;
  color: #000000de;
}

a:hover {
  text-decoration: none;
  color: #000000de;
}

.main {
  position: absolute;
  top: 64px;
  left: 240px;
  width: 86%;
  max-width: 86%;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-mobile {
  padding: 6rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.border {
  border: 1px solid black;
}

.text-start {
  text-align: start;
}

.gap-x {
  column-gap: 1rem;
}

.gap-y {
  row-gap: 1rem;
}

.lable {
  font-weight: bold !important;
}

iframe {
  border: 1px solid #cccccc;
  width: 100%;
  height: 100%;
}

select {
  padding: 1.2rem 0.8rem;
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}

.date-input:focus-visible {
  outline: none !important;
}

select:focus-visible {
  outline: 2px solid #007bff !important;
}

/* IMPORTTENT FOR RESPONSIVE EDITER */

.tinymce-mobile-outer-container .tinymce-mobile-disabled-mask{
  border: 1px solid #c4c4c4;
  background: #ffffff !important;
}


/* LOADING BOX */
.loading_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff6;
  z-index: 10000;
  flex-direction: column;
}

.grid-manage .MuiDataGrid-main div .MuiDataGrid-virtualScroller{
  height: 15.5rem !important;
}

.short_by_select{
  /* padding: 1rem 1rem 1rem 1rem; */
  border: none;
  border-bottom: 1px solid gray;
  border-radius: 0px;
  font-style: italic;
  padding :0.5rem 1rem;
}

.short_by_select:focus-visible{
  outline: none !important;
}


.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  /* animation: load7 1.8s infinite ease-in-out; */
  animation: load7 1.3s infinite ease-in-out;
}

.loader {
  color: #1976d2;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@media only screen and (max-width: 440px) {
  .DashboardDate {
    justify-content: center !important;
  }

}


/* RESPONSIVE UI */
@media only screen and (max-width: 576px) {
  .button-switch {
    /* justify-content: flex-start !important; */
    column-gap: 0rem;
    max-width: 50%;
    padding-top: 2rem;
  }
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 100% !important;
  margin: 0 auto;
}

.image_list_container img {
  box-shadow: 1px 0px 20px 2px #00000066;
}

.login_container {
  position: fixed;
  background-color: #ffffff;
  z-index: 100000;
  height: 100%;
  top: 64px;
  left: 0;
}

.DrageDropDashed-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  /* border: 2px dashed #757575; */
  flex-direction: column;
  border-radius: 3px;
}

/* .Drage_main{
  background-color: #edf4fb;
} */

.cstm-input {
  padding: 0.7rem;
  width: 100%;
  outline: none;
  margin-bottom: 0.5rem;
  border: 1px solid #cccccc;
}

.sort_by_button_media :hover{
  background-color: #e0e0e0;
  cursor: pointer;
}
.sort_by_button_media{
  padding: 0px 2rem 0px 10px;
  text-align: center;
}

@media screen and (max-width:805px) {
  .justify_sm_manage{
    justify-content: center !important;
  }
}


.manage-selfeA{
  width: 180px;
  justify-content: start !important;
}

.manage-self{
  width: 160px;
  display: flex;
  justify-content:flex-start;
}

@media screen and (max-width:1900px) {
  .manage-self{
    width: 160px;
  }
}
@media screen and (max-width:1870px) {
  .manage-self{
    width: 160px;
  }
}
